/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type QueueAudioChunkMutationVariables = Types.Exact<{
  input: Types.QueueAudioChunkInput;
}>;


export type QueueAudioChunkMutation = { __typename?: 'Mutation', queueAudioChunk?: { __typename?: 'QueueAudioChunkPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', id: string } | null } | null };

export const QueueAudioChunkDocument = gql`
    mutation QueueAudioChunk($input: QueueAudioChunkInput!) {
  queueAudioChunk(input: $input) {
    success
    appointmentRecording {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueueAudioChunkMutationService extends Mutation<QueueAudioChunkMutation, QueueAudioChunkMutationVariables> {
    document = QueueAudioChunkDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }