import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SecurityContext,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[omgTooltip]',
})
export class TooltipDirective extends Tooltip implements OnInit, OnChanges {
  @Input()
  public tooltipText: string;

  @Input()
  public showTooltip = true;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    el: ElementRef,
    zone: NgZone,
    private sanitizer: DomSanitizer,
    private primengConfig: PrimeNGConfig,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
  ) {
    super(platformId, el, zone, primengConfig, renderer, viewContainerRef);

    this.setOption({ showDelay: 250 });
  }

  public ngOnInit(): void {
    this.setOption({ disabled: !this.showTooltip });
    this.setSanitizedText(this.tooltipText);
    this.primengConfig.ripple = true;
  }

  public override create(): void {
    super.create();

    // When users zoom in the chart and it overflows (the right hand side of the chart goes off screen),
    // the default style of 'fit-width' was not working well. Resulted in the box being narrow,
    // and offset wrong. Setting it to `max-content` instead fixes the problem. Do not know or understand
    // why but it seems to work!
    if (this.fitContent) {
      this.container.style.width = 'max-content';
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    // Be sure to call PrimeNG's ngOnChanges! Otherwise props won't be updated right.
    super.ngOnChanges(changes);

    if (changes.showTooltip) {
      this.setOption({ disabled: !changes.showTooltip.currentValue });
    }

    if (changes.tooltipText) {
      this.setSanitizedText(changes.tooltipText.currentValue);
    }
  }

  private setSanitizedText(rawText: string): void {
    const safeHTML = this.sanitizer.sanitize(SecurityContext.HTML, rawText);

    this.setOption({ tooltipLabel: <string>safeHTML });
  }
}
