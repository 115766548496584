import { DestroyRef, inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ConfigService } from '@app/core/config';

import { Template } from './template-insertion.type';
import {
  ChartingTemplate,
  ChartingTemplatePurpose,
  ChartingTemplateType,
} from '../../../../graphql/onelife.type';
import {
  SearchChartingTemplatesQuery,
  SearchChartingTemplatesQueryService,
  SearchChartingTemplatesQueryVariables,
} from './search-charting-templates.onelife.generated';
import { ApolloQueryResult } from '@apollo/client';
import { QueryRef } from 'apollo-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface TemplateSearchQueryOptions {
  publicOnly?: boolean;
}

@Injectable()
export class TemplateSearchService {
  constructor(
    private config: ConfigService,
    private searchChartingTemplatesQueryService: SearchChartingTemplatesQueryService,
  ) {}

  private destroyRef = inject(DestroyRef);
  private templateSearchQueryRef:
    | QueryRef<
        SearchChartingTemplatesQuery,
        SearchChartingTemplatesQueryVariables
      >
    | undefined;

  search(
    text: string,
    index = this.config.searchIndex('message_templates'),
    options?: TemplateSearchQueryOptions,
  ): Observable<Template[]> {
    const type: ChartingTemplateType =
      index === this.config.searchIndex('message_templates')
        ? ChartingTemplateType.Message
        : ChartingTemplateType.Text;
    const purposes: ChartingTemplatePurpose[] = options?.publicOnly
      ? [ChartingTemplatePurpose.Public]
      : [ChartingTemplatePurpose.Personal, ChartingTemplatePurpose.Public];
    this.templateSearchQueryRef =
      this.searchChartingTemplatesQueryService.watch(
        {
          params: {
            first: 200,
            type: type,
            purposes: purposes,
            terms: text,
          },
        },
        { fetchPolicy: 'network-only' },
      );
    return this.templateSearchQueryRef.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => {
        return this.mapChartingTemplateQueryResultToEntity(response);
      }),
    );
  }

  private mapChartingTemplateQueryResultToEntity(
    response: ApolloQueryResult<SearchChartingTemplatesQuery>,
  ): Template[] {
    const edges = response?.data?.templates?.edges || [];
    return edges
      .filter(Boolean)
      .map(edge => edge!.node)
      .map((template: ChartingTemplate) => {
        return {
          id: parseInt(template.id),
          name: template.name,
          internal_user_id: template.internalUser
            ? parseInt(template.internalUser?.id)
            : undefined,
          body: template.body,
          purpose: template.purpose.toLowerCase(),
        };
      })
      .sort(this.sortPersonalFirst);
  }

  sortPersonalFirst(a: Template, b: Template): number {
    const aIsPersonal = a.purpose === 'personal';
    const bIsPersonal = b.purpose === 'personal';

    if ((aIsPersonal && bIsPersonal) || (!aIsPersonal && !bIsPersonal)) {
      return 0;
    }

    if (aIsPersonal) return -1;
    if (bIsPersonal) return 1;
    return 0;
  }
}
