/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type SearchChartingTemplatesQueryVariables = Types.Exact<{
  params: Types.ChartingTemplateSearchParams;
}>;


export type SearchChartingTemplatesQuery = { __typename?: 'Query', templates: { __typename?: 'ChartingTemplateConnection', total: number, edges?: Array<{ __typename?: 'ChartingTemplateEdge', node?: { __typename?: 'ChartingTemplate', id: string, name: string, body: string, type: Types.ChartingTemplateType, purpose: Types.ChartingTemplatePurpose, tags: Array<string>, updatedAt: string, updatedBy?: string | null, internalUser?: { __typename?: 'InternalUser', id: string } | null } | null } | null> | null } };

export const SearchChartingTemplatesDocument = gql`
    query SearchChartingTemplates($params: ChartingTemplateSearchParams!) {
  templates: chartingTemplates(params: $params) {
    total
    edges {
      node {
        id
        name
        body
        type
        purpose
        tags
        internalUser {
          id
        }
        updatedAt
        updatedBy
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchChartingTemplatesQueryService extends Query<SearchChartingTemplatesQuery, SearchChartingTemplatesQueryVariables> {
    document = SearchChartingTemplatesDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }