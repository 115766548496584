import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { Profile, ProfileSelectors } from '@app/core';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import { addDays, startOfDay } from '@app/utils';

import { Renewal, RenewalCartState } from './renewals.type';

function compareCartState(a: RenewalCartState, b: RenewalCartState): number {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function sortByPharmacyAndCartState(renewals: Renewal[]): Renewal[] {
  return renewals.sort(
    (a, b) =>
      a.pharmacy.id - b.pharmacy.id ||
      compareCartState(a.cartState, b.cartState),
  );
}

interface RenewalsByPharmacyId {
  [key: string]: Renewal[];
}

function renewalByPharmacyIdReducer(
  acc: RenewalsByPharmacyId,
  renewal: Renewal,
): RenewalsByPharmacyId {
  const pharmacyId = String(renewal.pharmacy.id);
  if (acc[pharmacyId]) {
    acc[pharmacyId].push(renewal);
  } else {
    acc[pharmacyId] = [renewal];
  }
  return acc;
}

export function sortRenewalsByPharmacy(
  checkoutRenewals: Renewal[],
): Renewal[][] {
  return Object.values<Renewal[]>(
    checkoutRenewals.reduce(renewalByPharmacyIdReducer, {}),
  );
}

export const isValidPrescriber = (
  profileId: number,
  validPrescribers: Prescriber[],
): boolean =>
  !!(
    profileId &&
    validPrescribers.find(prescriber => prescriber.id === profileId)
  );

export const calculateEarliestFillDate = (
  siblingFillDate: Date | null,
  renewalFillDate: Date | null,
): Date => {
  if (!renewalFillDate && !siblingFillDate) {
    return startOfDay(new Date());
  } else if (!renewalFillDate && !!siblingFillDate) {
    return startOfDay(addDays(siblingFillDate, 30));
  } else {
    return startOfDay(renewalFillDate!);
  }
};

export const cartDenials = (renewals: Renewal[]): Renewal[] =>
  renewals.filter(renewal => renewal.cartState === RenewalCartState.denied);

export const cartApprovals = (renewals: Renewal[]): Renewal[] =>
  renewals.filter(renewal => renewal.cartState === RenewalCartState.approved);

@Injectable()
export class ReadyForCheckoutValidator {
  constructor(private profileSelectors: ProfileSelectors) {}

  isValid(renewal: Renewal): Observable<boolean> {
    return this.profileSelectors.profile.pipe(
      withLatestFrom(this.profileSelectors.hasRole('provider')),
      map(([profile, isProvider]) =>
        this.validate(renewal, profile, isProvider),
      ),
    );
  }

  validate(renewal: Renewal, profile: Profile, isProvider: boolean): boolean {
    const { prescriber, cartState } = renewal;
    if (!prescriber || !prescriber.id) {
      return false;
    }
    if (cartState === RenewalCartState.pending) {
      return false;
    }
    if (
      this.renewalProcessedBySomeoneElse(profile.id, prescriber.id) &&
      this.mustSelfCheckout(renewal, profile, isProvider)
    ) {
      return false;
    }

    return true;
  }

  private mustSelfCheckout(
    renewal: Renewal,
    profile: Profile,
    isProvider: boolean,
  ): boolean {
    const { dispensableRestrictedControlledSubstance, validPrescribers } =
      renewal;
    const { hasSignatureImage } = profile;

    const cannotSignOnBehalfOf = !this.canSignOnBehalfOf(
      validPrescribers,
      hasSignatureImage,
      isProvider,
    );
    return dispensableRestrictedControlledSubstance || cannotSignOnBehalfOf;
  }

  private renewalProcessedBySomeoneElse(
    profileId: number,
    prescriberId: number,
  ): boolean {
    return prescriberId !== profileId;
  }

  private canSignOnBehalfOf(
    validPrescribers: Prescriber[] = [],
    hasSignatureImage: boolean,
    isProvider,
  ): boolean {
    return validPrescribers.length > 0 && hasSignatureImage && isProvider;
  }
}
