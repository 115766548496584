import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { lastValueFrom, Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AnalyticsService,
  PatientSelectors,
  ProfileSelectors,
} from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { MedicationRegimen } from '@app/modules/medications/shared/medications.type';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import {
  numberOfValidPrescribers,
  freeTextLengthMax,
} from '@app/modules/shared-rx/utils';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { DropdownItem } from '@app/shared';
import { RenewalForm } from './../../shared/renewal-form';
import { RenewalFormService } from '../../shared/renewal-form.service';
import { isValidPrescriber } from '../../shared/renewal-utils';
import { RenewalGuard } from '../../shared/renewal.guard';
import { Renewal, RenewalClass } from '../../shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '../../store';
import { Problem } from '@app/modules/problems/shared/problems.type';
import { ProblemAttachmentsService } from '@app/modules/problems/shared/problem-attachments.service';
import { ProblemAttachmentAttachmentEnum } from '../../../../../graphql/onelife.type';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';

@Component({
  selector: 'omg-renewal-item-detail',
  templateUrl: './renewal-item-detail.component.html',
  styleUrls: ['./renewal-item-detail.component.scss'],
})
export class RenewalItemDetailComponent implements OnInit, OnDestroy {
  cartComplete$: Observable<boolean>;
  renewal$: Observable<Renewal>;
  todo$: Observable<Todo>;
  form: RenewalForm;
  needsPediatricVitals: boolean;
  numberOfValidPrescribers: number;
  regimenEditingInProgress: boolean;
  isProvider: boolean;
  dispensableRestrictedControlledSubstance: boolean;
  userCanPrescribe: boolean;
  pediatricVitalsEnabled$: Observable<boolean>;
  longSig: boolean;

  sortedValidPrescribers$: Observable<Prescriber[]>;
  credentialsLoading$: Observable<boolean>;
  credentialItems$: Observable<DropdownItem[]>;

  problemToMedsLinkingEnabled: boolean;
  linkedProblemsEditingEndabled: boolean;
  attachedProblemIds$: Observable<number[]>;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private route: ActivatedRoute,
    private todoActions: TodoActions,
    private todoSelectors: TodoSelectors,
    private renewalFormService: RenewalFormService,
    private renewalActions: RenewalActions,
    private profileSelectors: ProfileSelectors,
    private renewalGuard: RenewalGuard,
    private patientSelectors: PatientSelectors,
    private launchDarklyService: LaunchDarklyService,
    private problemAttachmentsService: ProblemAttachmentsService,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.renewalGuard
      .canActivate(this.route.snapshot)
      .pipe(filter(Boolean))
      .subscribe(() => this.setupSelectors());

    const meds2ProblemsFF = this.launchDarklyService.variation<number>(
      FeatureFlagNames.problemToMedsLinkingPhases,
      0,
    );

    this.problemToMedsLinkingEnabled = meds2ProblemsFF >= 3;
    this.linkedProblemsEditingEndabled = meds2ProblemsFF >= 4;
  }

  get disabled(): boolean {
    return (
      !this.form ||
      this.form.model.saving ||
      this.regimenEditingInProgress ||
      (!this.userCanPrescribe && this.dispensableRestrictedControlledSubstance)
    );
  }

  get disabledApproval(): boolean {
    return (
      this.disabled ||
      !this.form.valid ||
      !this.isProvider ||
      this.needsPediatricVitals ||
      this.longSig
    );
  }

  ngOnDestroy(): void {
    this.form?.unsubscribe();
  }

  setRegimenEditingInProgress(editing: boolean): void {
    this.regimenEditingInProgress = editing;
  }

  updatePediatricVitalsStatus(hasVitals: boolean): void {
    this.needsPediatricVitals = !hasVitals;
  }

  regimenUpdate(medicationRegimen: MedicationRegimen): Observable<Renewal> {
    const changes = {
      medicationRegimenId: medicationRegimen.id,
      className: this.form.renewal.className,
      medicationRegimen,
    };
    this.renewalActions.update({ id: this.form.renewal.id, changes });

    return this.renewalSelectors.getById(this.form.renewal.id);
  }

  async problemAttachmentRemoved(
    problem: Problem,
    patientMedId: number,
  ): Promise<void> {
    const result$ = this.problemAttachmentsService.unlinkAttachment(
      problem.id,
      ProblemAttachmentAttachmentEnum.PatientMedication,
      patientMedId,
    );
    // subscribe to ensure call is made
    await lastValueFrom(result$);

    this.analytics.track(AnalyticsEvent.ProblemAttachmentUnlinked, {
      workflow: 'Charting',
      component: 'Medications',
      subcomponent: 'RxRenewalItem',
    });
  }

  async problemAttachmentAdded(
    problem: Problem,
    patientMedId: number,
  ): Promise<void> {
    const result$ = this.problemAttachmentsService.createAttachment(
      problem.id,
      ProblemAttachmentAttachmentEnum.PatientMedication,
      patientMedId,
    );

    // subscribe to ensure the call is made
    await lastValueFrom(result$);

    this.analytics.track(AnalyticsEvent.ProblemAttachmentLinked, {
      workflow: 'Charting',
      component: 'Medications',
      subcomponent: 'RxRenewalItem',
    });
  }

  shouldDisplayProblemAttachmentsComponent(
    problemsIds: number[],
    renewal: Renewal,
  ): boolean {
    // displaying read only mode on, and we have problems to display
    if (this.problemToMedsLinkingEnabled && problemsIds.length > 0) {
      return true;
    }

    // editing is enabled, and we have a linked patientMed to create problem attachments to.
    return this.linkedProblemsEditingEndabled && !!renewal.patientMedicationId;
  }

  private setupSelectors(): void {
    this.renewal$ = this.route.paramMap.pipe(
      map((params: ParamMap) => +params.get('id')!),
      tap((id: number) =>
        this.renewalActions.updateCartState({ currentRenewalId: id }),
      ),
      switchMap((id: number) => this.renewalSelectors.getById(id)),
      filter(renewal => !!renewal),
      tap(renewal => {
        this.numberOfValidPrescribers = numberOfValidPrescribers(renewal);
        this.credentialItems$ =
          this.renewalSelectors.credentialDropdownItemsFor(renewal.id);
        this.sortedValidPrescribers$ =
          this.renewalSelectors.sortedValidPrescribers(renewal.id);
        this.longSig =
          renewal.medForDisplay.instructions.length > freeTextLengthMax;
        this.dispensableRestrictedControlledSubstance =
          renewal.dispensableRestrictedControlledSubstance;
      }),
      shareReplay(1),
    );

    this.renewal$
      .pipe(withLatestFrom(this.profileSelectors.profileId), take(1))
      .subscribe(([renewal, profileId]) => {
        this.userCanPrescribe = isValidPrescriber(
          profileId,
          renewal.validPrescribers,
        );
        this.form = this.renewalFormService.buildForm(renewal, profileId);
        this.loadAndSetTodo(renewal.id, renewal.className);
      });

    this.profileSelectors
      .hasRole('provider')
      .pipe(take(1))
      .subscribe(isProvider => {
        this.isProvider = isProvider;
      });

    this.pediatricVitalsEnabled$ = this.patientSelectors.isMinor;
    this.cartComplete$ = this.renewalSelectors.cartComplete;
    this.credentialsLoading$ =
      this.renewalSelectors.prescriberCredentialsLoading;

    this.initAttachedProblemIds$();
  }

  private loadAndSetTodo(id: number, className: RenewalClass): void {
    this.todoActions.loadTodoByEntity(id, className);
    this.todo$ = this.todoSelectors.todoByEntity(id, className);
  }

  private initAttachedProblemIds$(): void {
    this.attachedProblemIds$ = this.renewal$.pipe(
      map(renewal => renewal.patientMedicationId),
      switchMap(linkedPatientMedId => {
        if (linkedPatientMedId) {
          return this.problemAttachmentsService.getLegacyProblemIdsByAttachment(
            ProblemAttachmentAttachmentEnum.PatientMedication,
            linkedPatientMedId,
          );
        }

        return of([]);
      }),
    );
  }
}
