<div *ngIf="label" class="label" (click)="focus()">{{ label }}</div>
<p-dropdown
  #dropdown
  autoWidth="false"
  [selectOnFocus]="true"
  [options]="options"
  [optionLabel]="optionLabel"
  [optionValue]="optionKey"
  [placeholder]="placeholder"
  [group]="group"
  [filter]="filter"
  [filterBy]="filterBy"
  [filterPlaceholder]="filterPlaceholder"
  [tabindex]="tabindex"
  [disabled]="disabled"
  [readonly]="calculatedReadOnly"
  [required]="required"
  [style]="inlineStyle"
  [ngStyle]="inlineStyle"
  [styleClass]="styleClass"
  [appendTo]="appendTo"
  [dropdownIcon]="dropdownIcon"
  [ngClass]="{ 'custom-dropdown-icon': !dropdownIcon }"
  (onShow)="onShow($event)"
  (onHide)="onHide($event)"
  (onFocus)="onFocus()"
  (onChange)="onUpdate()"
  [(ngModel)]="value"
>
  <ng-template let-item pTemplate="item">
    <div [attr.aria-label]="item.value">
      {{ item[optionLabel] || item.label || item }}
      @if (item.iconClass) {
        <i class="{{ item.iconClass }}"></i>
      }
    </div>
  </ng-template>
</p-dropdown>
